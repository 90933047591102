/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Wrapper, Footer, Container, FooterCols, Accreditations, FooterLink, FooterRow } from '../styles/styles'

import logo1 from "../images/logo1.jpg"
import logo2 from "../images/logo2.png"
import logo3 from "../images/logo3.png"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Wrapper>
        <main style={{
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          background:'#f5edf4'
        }}>
          {children}
        </main>
      </Wrapper>
      <Accreditations style={{display:'flex', justifyContent:'center'}}>
        <Container style={{maxWidth:'1440px', textAlign:'center'}}>
          <img src={logo3} alt="partner logo" />
          <img src={logo2} alt="partner logo" />
          <img src={logo1} alt="partner logo" />
        </Container>
      </Accreditations>
      <Footer>
        <Container style={{maxWidth:'1440px'}}>
          <FooterCols>
            <div>
              <h4>Services</h4>
              <ul>
                <li>
                  <Link to="/coaching">Coaching</Link>
                </li>
                <li>
                  <Link to="/leadership-management-and-personal-development">Leadership / Management and Personal Development</Link>
                </li>
                <li>
                  <Link to="/diversity-equity-and-inclusion">Diversity, Equity and Inclusion</Link>
                </li>
              </ul>
            </div>
            {/* <div>
              <h4>Links</h4>
              <ul>
                <li>
                  <Link to="/">Link 1</Link>
                </li>
                <li>
                  <Link to="/">Link 2</Link>
                </li>
                <li>
                  <Link to="/">Link 3</Link>
                </li>
              </ul>
            </div> */}
          </FooterCols>
          <FooterRow>
            <p>© {new Date().getFullYear()} | MTraining Solutions Limited.</p>
            <p>Company No. 8288552</p>
            <FooterLink href="mailto:enquires@mtrain.uk">enquires@mtrain.uk</FooterLink>
          </FooterRow>
        </Container>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
