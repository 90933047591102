import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { purple, NavBar, Container, NavUl, Burger, CompanyLogo } from '../styles/styles'
import MobileMenu from "./mobileMenu"
import logo from "../images/company-logo.png"

const Header = ({ siteTitle }) => {

  const nav = useStaticQuery(graphql`
    query {
      allSitePage {
        edges {
          node {
            context {
              name
              content
            }
          }
        }
      }
    }
  `)

  const [scrolled, setScrolled] = useState(false)
  const [navLinks, setNavLinks] = useState(
    [nav.allSitePage.edges[0].node.context, nav.allSitePage.edges[1].node.context, nav.allSitePage.edges[2].node.context]
    // nav.allSitePage.edges.filter(item => item.node.context.name !== null)
  )
  const [mobileActive, setMobileActive] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
  }, [])

  const handleScroll = () => {
    window.scrollY > 10 ? setScrolled(true) : setScrolled(false)
  }

  const handleMobileMenu = () => {
    setMobileActive(!mobileActive)
  }

  return (
    <NavBar
      className={ scrolled ? '--mob' : '' }
    >
      <Container style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '1440px',
      }}>
        <div style={{
          zIndex: 1
        }}>
          <Link to="/" style={{
            textDecoration: 'none',
            color: 'white'
          }}>
            <CompanyLogo src={logo} alt="logo" />
          </Link>
        </div>
        <div>
          <NavUl>
            <li>
              <Link to="/" activeClassName="__active">Home</Link>
            </li>
            <li>
              <Link to="/about/" activeClassName="__active">About</Link>
            </li>
            <li>
              <Link to="/services/" activeClassName="__active">Services</Link>
              <ul>
                <li>
                  <Link to="/coaching">Coaching</Link>
                </li>
                <li>
                  <Link to="/leadership-management-and-personal-development">Leadership / Management and Personal Development</Link>
                </li>
                <li>
                  <Link to="/diversity-equity-and-inclusion">Diversity, Equity and Inclusion</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/contact/" activeClassName="__active">Contact</Link>
            </li>
          </NavUl>
          <Burger onClick={handleMobileMenu} className={mobileActive && '__active'}>
            <div />
          </Burger>
          <MobileMenu isActive={mobileActive} navLinks={navLinks} />
        </div>
      </Container>
    </NavBar>
  )
}



Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
