import styled from 'styled-components'
import { Link } from "gatsby"
import dot from '../images/dot.png'

// export const purple = '#c67bff'
// export const purple = '#631b9a'
export const purple = '#59195E'
export const newGrey = '#505050'

export const Wrapper = styled.div`
    font-family: 'Alata', sans-serif;
    position: relative;
    /* padding-top: 100px; */
`

export const Container = styled.div`
    padding: 0 15px;
    position: relative;
    width: 100%;
    max-width: 1440px;

    @media(min-width: 650px){
        max-width:576px;
    }
    @media(min-width: 992px){
        max-width:1200px;
    }
    @media(min-width: 1500px){
        max-width:1440px;
    }
`

export const ContentP = styled.div`
    max-width: 900px;
    margin: 0 auto;
`

export const ContentWrap = styled.div`
    position: relative;

    @media(min-width: 650px) {
        margin-top: 20px;
    }

    @media(min-width: 992px) {
        margin-top: 0;
    }
`

export const ImageWrap = styled.div`
    width: 100%;
    margin-bottom: 15px;
    z-index: 1;

    // &::after {
    //     background-image: url(${dot});
    //     background-size: 5%;
    //     width: 290px;
    //     height: 200px;
    //     position: absolute;
    //     top: -32px;
    //     left: -32px;
    //     z-index: 0;
    // }

    @media(min-width: 650px) {
        min-width: 45%;
        margin-right: 30px;
        margin-bottom: 0;

        &::after {
            content: '';
        }

    }

    @media(min-width: 1500px) {
        margin-right: 60px;
    }
`

export const CompanyLogo = styled.img`
    margin-bottom: 0;
    width: 80px;

    @media(min-width: 992px) {
        width: 130px;
    }
`

export const NavBar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100px;
    padding: 30px 0;
    /* background: rgba(255,255,255,0.2); */
    z-index: 1;
    display: flex;
    justify-content: center;
    color: white;
    z-index: 99;
    transition: background ease .3s;

    @media(min-width: 480px) {
        padding: 10px 50px;
    }

    &.--mob {
        background ${purple};
    }
`
export const NavUl = styled.ul`
    display: none;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -15px;
    list-style: none;

    li {
        position: relative;
        margin: 0 15px;
        padding: 20px 0;
        cursor: pointer;
        
        & > a {
            position: relative;
            font-family: 'Alata', sans-serif;
            text-decoration: none;
            color: white;

            &.__active {

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: white;
                }
            }
        }

        & > ul {
            position: absolute;
            min-width: 272px;
            top: 100%;
            left: -111px;
            background: white;
            padding: 25px;
            margin: 0;
            border-radius: 5px;
            box-shadow: 0 4px 9px -3px rgba(0,0,0,0.4);
            opacity: 0;
            transform: translateY(50px);
            pointer-events: none;
            transition: all ease 0.2s;

            &::before {
                content: '';
                position: absolute;
                top: -9px;
                right: 44%;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #ffffff;
            }

            & > li {
                padding: 5px 0;
                margin: 0;

                & > a {
                    display: block;
                    color: ${newGrey};
                    opacity: 0.7;
                    transition: all ease 0.2s;

                    &:hover,
                    &:focus {
                        opacity: 1;
                        transform: translateX(5px);
                    }
                }
            }
        }

        &:hover,
        &:focus {

            & > ul {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }
        }
    }

    @media(min-width: 992px) {
        display: flex;
    }
`

export const SmallBannerDiv = styled.div`
    position: relative;
    width: 100%;
    padding: 200px 0 150px 0;
    background: red;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 50px;

    @media(min-width: 480px) {
        padding: 200px 50px 150px 50px;
    }

    h1 {
        color: white;
        font-size: 2.5rem;
    }
`

export const CoverImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(309deg,rgb(0 0 0 / 77%) 0%,rgb(44 14 60 / 96%) 100%);
    }
`

export const StyledBanner = styled.div`
    position: relative;
    height: 100vh;
    min-height: 400px;
    max-height: 500px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
    }

    .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        picture img {
            object-position: top center !important;
        }
    }
`

export const PaddedBannerDiv = styled.div`
    position: relative;
    /* padding: 60px; */
    width: 100%;
    height: 100vh;

    & > div {
        position: relative;
        width: 100%;
        height: 100%;
        background: black;

        > div {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 40px 10vw;
            background: rgba(255,255,255,0.4);
            /* background: linear-gradient(309deg,rgba(250,200,255,0.5018382352941176) 0%,rgb(44 14 60 / 96%) 100%); */
            /* background: linear-gradient(309deg,rgb(91 245 238 / 25%) 0%,rgb(44 14 60 / 96%) 100%); */          
            background: linear-gradient(-10deg,rgb(91 245 238 / 32%) 0%,rgb(44 14 60 / 110%) 100%);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            color: white;

            h1 {
                font-size: 3rem;
            }
        }

        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }

        .gatsby-image-wrapper {
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            & > picture > img {
                object-position: top center !important;
            }
        }

    }
    @media(min-width: 992px){
        height: 550px;

        & > div > div h1 {
            font-size: 4.5rem;
        }
    }
`

export const CtaButton = styled(Link)`
    position: relative;
    display: inline-block;
    padding: 10px 25px;
    background: ${purple};
    /* border-radius: 5px; */
    color: white;
    box-shadow: 7px 7px 0 0 rgba(0,0,0,0.3);
    text-decoration: none;
    transition: all ease 0.2s;

    &:hover,
    &:focus {
        transform: translate(7px, 7px);
        box-shadow: none;
    }
`

export const TripleContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 120px 0;

    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding: 20px;

        & > a {
            position: relative;
            background: white;
            width: 100%;
            padding: 40px;
            margin-bottom: 20px;
            /* border-radius: 5px; */
            box-shadow: 7px 7px 0 0 rgba(0,0,0,0.3);
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: all ease 0.2s;
            text-decoration: none;
            color: black;
            text-align: center;

            & > h4 {
                font-size: 1.4rem;
                line-height: 1.3;
                min-height: 60px;
                /* text-shadow: 2px 2px 0 #adfaf6; */
                /* color: #c67bff; */
                color: ${newGrey};
            }

            & > div > svg {
                width: 90px;
                transition: all ease 0.2s;
                /* filter: drop-shadow(3px 3px 0 rgb(91 245 238 / 50%)); */
                filter: drop-shadow(3px 3px 0 rgba(0, 255, 255, 0.3));
                fill: ${purple};
            }

            &:hover,
            &:focus {
                transform: translate(6px, 6px);
                box-shadow: 1px 1px 0 0 rgba(0,0,0,0.6);

                /* & > svg {
                    transform: scale(1.1);
                } */
            }
        }
    }

    @media(min-width: 992px) {

        & > div {

            & > a {
                width: 30%;
            }
        }
    }
`

export const DummyButton = styled.div`
    position: relative;
    padding: 10px 25px;
    background: ${purple};
    /* border-radius: 5px; */
    color: white;
    text-decoration: none;
`

export const SectionTitle = styled.h3`
    position: relative;
    font-size: 45px;
    padding-bottom: 20px;
    margin-bottom: 50px;
    color: ${newGrey};

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 2px;
        width: 50px;
        height: 3px;
        background: ${newGrey};
    }
`

export const ImageLeft = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 120px 0;
    justify-content: center;

    div:first-of-type {
        display: ${props => props.mobNoImage ? 'none' : 'block'}
    } 

    @media(min-width: 992px) {
        flex-wrap: nowrap;
        justify-content: auto;

        div:first-of-type {
            display: block;
        }
    }
`

export const Footer = styled.footer`
    position: relative;
    display: block;
    background: #2d2d2d;
    padding: 50px 15px;
    display: flex;
    justify-content: center;
    color: #f5edf4;
    border-top: solid 5px ${purple};
    font-family: 'Alata', sans-serif;

    @media(min-width: 650px) {
        padding: 80px;
    }
`

export const FooterCols = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    & > div {
        display: flex;
        flex-direction: column;
        margin-right: 50px;
        margin-bottom: 25px;

        & > h4 {
            position: relative;
            font-size: 30px;
            font-weight: normal;
            padding-bottom: 15px;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 2px;
                background: #f5edf4;
            }
        }

        & > ul {
            list-style: none;
            padding: 0;
            margin: 0;

            a {
                color: #f5edf4;
                text-decoration: none;
                opacity: 0.7;
                transition: opacity ease 0.2s;

                &:hover,
                &:focus {
                    opacity: 1;
                }
            }
        }
    }

    @media(min-width: 650px) {

        & > div {
            margin-bottom: 0;
        }
    }
`

export const ContactBlock = styled.div`
    position: relative;
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContactForm = styled.form`

    input, textarea {
        padding: 15px 30px;
        background: #e6d7e4;
        border: none;
        color: ${newGrey};
        box-shadow: 7px 7px 0 0 rgba(0,0,0,0.3);

        &:focus {
            outline: none;
        }
    }

    textarea {
        min-width: 100%;
        max-width: 100%;
        min-height: 200px;
        max-height: 300px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100px;
            height: 100%;
            background: red;
        }
    }

    & > div:first-of-type {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        input {
            width: 49%;
        }
    }
    & > div:last-of-type {
        margin-bottom: 25px;
    }

    button {
        position: relative;
        display: inline-block;
        padding: 10px 25px;
        background: ${purple};
        /* border-radius: 5px; */
        color: white;
        box-shadow: 7px 7px 0 0 rgba(0,0,0,0.3);
        text-decoration: none;
        border: none;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover,
        &:focus {
            transform: translate(7px, 7px);
            box-shadow: none;
        }
    }
`

export const PhoneLink = styled.a`
    position: relative;
    margin: 0;
    font-size: 2rem;
    color: #631b9a;
    text-shadow: 2px 2px #abf2f7;
    text-decoration: none;
    line-height: 1;

    &::after {
        content: '';
        position: absolute;
        bottom: -13px;
        left: 0;
        width: 50px;
        height: 3px;
        background: ${purple};
        border-radius: 2px;
        transition: width ease 0.2s;
    }

    &:hover,
    &:focus {
        &::after {
            width: 100%;
        }
    }
`

export const Burger = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 99;

    div {
        position: relative;
        width: 100%;
        height: 3px;
        background: white;
        transition: all ease .2s;

        &::after {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
            height: 3px;
            background: white;
            transition: all ease .2s;
        }
        &::before {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 3px;
            background: white;
            transition: all ease .2s;
        }
    }

    &.__active {
        div {
            background: none;

            &::after {
                content: '';
                top: auto;
                transform: rotate(45deg);
            }
            &::before {
                content: '';
                bottom: auto;
                transform: rotate(-45deg);
            }
        }
    }

    @media(min-width: 992px) {
        display: none;
    }
`

export const MobileNav = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    padding-top: 100px;
    background: ${purple};
    overflow: hidden;
    transition: width ease .2s;

    & > div {
        width: 100vw;
        padding: 20px;

        ul {
            list-style: none;

            & > li {

                a {
                    display: block;
                    padding: 5px 10px;
                    font-family: 'Alata', sans-serif;
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }

    &.__active {
        width: 100vw;
    }

    @media(min-width: 992px) {
        display: none;
    }
`

export const Accreditations = styled.div`
    border-top: solid 5px ${purple};

    img {
        margin: 20px;
        height: 90px;
    }
`

export const FooterRow = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media(min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const FooterLink = styled.a`
    color: white;
    text-decoration: none;
    margin-bottom: 1rem;
`