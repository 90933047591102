import React, { Component } from 'react'
import { Link } from "gatsby"
import { MobileNav } from '../styles/styles'

const MobileMenu = ({ isActive, navLinks }) => {
	return (
		<MobileNav className={isActive && '__active'}>
			<div>
				<ul>
					<li>
						<Link to="/" activeClassName="__active">Home</Link>
					</li>
					<li>
						<Link to="/about/" activeClassName="__active">About</Link>
					</li>
					<li>
						<Link to="/services/" activeClassName="__active">Services</Link>
						<ul>
							<li>
								<Link to="/coaching">Coaching</Link>
							</li>
							<li>
								<Link to="/leadership-management-and-personal-development">Leadership / Management and Personal Development</Link>
							</li>
							<li>
								<Link to="/diversity-equity-and-inclusion">Diversity, Equity and Inclusion</Link>
							</li>
						</ul>
					</li>
					<li>
						<Link to="/contact/" activeClassName="__active">Contact</Link>
					</li>
				</ul>
			</div>

		</MobileNav>
	)
}

export default MobileMenu